import React, { lazy, Suspense, ReactNode } from 'react'
import { PageContentSlot } from '@wf-mfe/layout'
// @ts-expect-error not exported types
import { getObjCodeForTopLevelRoute } from '@wf-mfe/navigation'
import { useLocation, useParams } from 'react-router-dom'
import { ErrorBoundary } from '../utils/logger'
import { getObjectTypeFromPath } from '../utils/helpers'

const PlanningView = lazy(() =>
  import('@wf-mfe-maestro/components').then((module) => ({
    default: module.PlanningView,
  }))
)

const ViewStoreProvider: React.FC<{ children: ReactNode }> = React.lazy(() =>
  System.import('@wf-mfe-maestro/view-service').then((module) => ({
    default: module.ViewStoreProvider,
  }))
)

export const PlanningConnectionPage = () => {
  const { ID } = useParams()

  const { pathname } = useLocation()
  const objType = getObjectTypeFromPath(pathname)

  const objCode = getObjCodeForTopLevelRoute(objType)

  return (
    <PageContentSlot mfeName="@wf-mfe/maestro-wrapper-ui">
      <Suspense fallback={null}>
        <ErrorBoundary>
          <ViewStoreProvider>
            <PlanningView
              connectionName="workfront"
              objectName={objCode}
              recordId={ID || ''}
            />
          </ViewStoreProvider>
        </ErrorBoundary>
      </Suspense>
    </PageContentSlot>
  )
}

export const PlanningConnectionRouter = () => {
  return <PlanningConnectionPage />
}
